import {
    pushCheckoutErrors,
    pushMembershipSelectedToCart,
    pushRemovedProductFromCart,
    pushSelectMembershipEvent,
} from './../gtm/checkoutFlow';
import { updateMiniCart } from './orderSummary';
import { dynamicCountdown } from '../util';
import payementInitialize from './paymentScheduleUpdate';
import { equalizeTiles } from '../membershipTilesSwiper';

/**
 * Handle the USP's view more/view less button clicks on the membership checkout step 2
 */
const handleMembershipUSP = () => {
    const $viewMore = $('.js-view-more-usp');
    const $viewLess = $('.js-view-less-usp');
    const $allUSP = $('.js-usp-list-all');

    $viewMore.on('click', () => {
        $viewMore.hide('fast');
        $viewLess.show('fast');
        $allUSP.show('fast');
    });

    $viewLess.on('click', () => {
        $viewMore.show('fast');
        $viewLess.hide('fast');
        $allUSP.hide('fast');
    });
};

/**
 * Updates membership step
 * @param {Object} data - Payload data from ajax call
 */
const updateMembershipBlock = data => {
    const $membershipStep = $('.membership-step');
    $membershipStep.empty().append(data.membershipsData);
};

/**
 * Resets membership tables
 * @param {HTMLElements} $membershipTables - List of membership tables
 */
const resetMembershipTables = $membershipTables => {
    $membershipTables.each((i, table) => {
        const $membershipTable = $(table);
        $membershipTable.removeClass('highlighted');
        $membershipTable.removeClass('selected');
    });
};

/**
 * Resets membership duration wrapper
 * @param {HTMLElement} $membershipDuration - Membership duration wrapper
 * @param {string} name - Membership name
 */
const resetMembershipDurationWrapper = ($membershipDuration, name) => {
    ['basic', 'comfort', 'premium', 'all-in', 'ultimate'].forEach(className => {
        $membershipDuration.removeClass(className);
    });
    $membershipDuration.addClass(name);
};

/**
 * Resets membership duration radio buttons
 * @param {HTMLElements} $membershipDurations - List of membership duration radio buttons
 * @param {HTMLElement} $currentMembershipDuration - Current membership duration radio button that is being clicked
 */
const resetMembershipDurations = ($membershipDurations, $currentMembershipDuration) => {
    $membershipDurations.each((i, duration) => {
        const $membershipDuration = $(duration);
        $membershipDuration.prop('checked', false);
        $membershipDuration.removeClass('checked');
    });
    $currentMembershipDuration.prop('checked', true);
    $currentMembershipDuration.addClass('checked');
};

/**
 * Handles click on the membership button
 * @param {Event} e - Click event
 */
const handleMembershipButtonClick = e => {
    const $membershipButton = $(e.currentTarget);
    const $membershipTables = $('.js-membership-block');
    const $membershipStepWrapper = $membershipButton.closest('.js-step-02-content');
    const $membershipTable = $membershipButton.closest('.js-membership-block');
    const $membershipDurationWrapper = $membershipStepWrapper.find('.js-membership-duration-wrapper');
    const $nextStepButton = $membershipStepWrapper.find('.js-next-step');
    const membershipUrl = $membershipTable.attr('data-url');
    const membershipName = $membershipTable.attr('data-name');
    const $selectedMembershipDuration = $membershipDurationWrapper.find(`.js-membership-contract-length[data-membership-name=${membershipName}][type=radio].checked`);
    const durationId = $selectedMembershipDuration.attr('data-pid');

    resetMembershipTables($membershipTables);
    resetMembershipDurationWrapper($membershipDurationWrapper, membershipName);
    addMembershipPlanToCart(membershipUrl, durationId, null, false, true); // eslint-disable-line no-use-before-define
    $membershipTable.addClass('highlighted');
    $membershipTable.addClass('selected');
    $nextStepButton.removeClass('display-none');
};

/**
 * Updates membership table HTML
 * @param {HTMLElement} $membershipTable - Membership table
 * @param {string} membershipTileHTML - Membership table html string
 */
const updateMembershipTable = ($membershipTable, membershipTileHTML) => {
    const membershipTileContent = $(membershipTileHTML).html();

    if ($membershipTable && membershipTileContent) {
        $membershipTable.empty().append(membershipTileContent);
        const $membershipButton = $membershipTable.find('.js-membership-btn-new');
        $membershipButton.on('click', handleMembershipButtonClick);
        handleMembershipUSP();
    }
};

/**
 * Sends ajax call to add membership plan to cart
 * @param {string} url - Url to send ajax call to
 * @param {string} pid - Membership duration plan id
 * @param {?HTMLElement} $membershipTable - Membership table
 * @param {boolean} isUSPSectionExpanded - True if USP section needs to be expanded, False otherwise
 * @param {boolean} isMembershipButtonClick - True if the table membership button was clicked, False otherwise
 */
function addMembershipPlanToCart(url, pid, $membershipTable, isUSPSectionExpanded, isMembershipButtonClick) {
    $('.js-checkout-order-summary').css('pointer-events', 'none');

    $.ajax({
        url,
        method: 'POST',
        data: {
            pid,
            isUSPSectionExpanded,
        },
        success: ({
            cart,
            error,
            errorMessage,
            gtmProducts,
            gtmProductsNew,
            gtmRemovedProducts,
            membershipTileHTML,
        }) => {
            if (error) {
                pushCheckoutErrors('checkout', errorMessage || error.errorText);
                return;
            }

            const $checkoutStage = $('.js-data-checkout-stage');

            updateMembershipTable($membershipTable, membershipTileHTML);
            equalizeTiles();
            payementInitialize();
            updateMiniCart();

            if (gtmProducts) {
                $checkoutStage.data('gtm-products', JSON.parse(gtmProducts));
            }

            if (gtmProductsNew) {
                pushMembershipSelectedToCart(JSON.parse(gtmProductsNew));
                $checkoutStage.data('gtm-membership-products', gtmProductsNew);
            }

            if (gtmProductsNew && isMembershipButtonClick) {
                const isFoundingMember = cart && cart.preSalePromotionData && cart.preSalePromotionData.available;
                pushSelectMembershipEvent(JSON.parse(gtmProductsNew), isFoundingMember);
            }

            if (gtmRemovedProducts && JSON.parse(gtmRemovedProducts).items.length > 0) {
                pushRemovedProductFromCart(JSON.parse(gtmRemovedProducts));
                $checkoutStage.data('gtm-removed-products', gtmRemovedProducts);
                sessionStorage.setItem('gtmRemoveProduct', gtmRemovedProducts);
            }
        },
        error: ({
            error,
            errorMessage,
        }) => {
            $('.js-checkout-order-summary').css('pointer-events', 'auto');
            pushCheckoutErrors('checkout', errorMessage || error.errorText);
        },
    });
}

/**
 * Binds click event to the membership selection buttons
 */
function handleMembershipSelection() {
    const $membershipButtons = $('.js-membership-btn-new');

    $membershipButtons.each((i, button) => {
        const $membershipButton = $(button);
        $membershipButton.on('click', handleMembershipButtonClick);
    });

    dynamicCountdown();
}

/**
 * Binds click event to the membership duration radio buttons
 */
const handleMembershipDuration = () => {
    const $membershipDurations = $('.js-membership-contract-length');

    $membershipDurations.each((i, durationItem) => {
        const $membershipDuration = $(durationItem);
        const url = $membershipDuration.attr('data-url');
        const pid = $membershipDuration.attr('data-pid');
        const membershipName = $membershipDuration.attr('data-membership-name');
        const $currentMembershipDurations = $(`.js-membership-contract-length[data-membership-name=${membershipName}]`);
        const $membershipTable = $(`.js-membership-block.${membershipName}`);
        const $allUSP = $('.js-usp-list-all');

        $membershipDuration.on('change', () => {
            const isUSPSectionExpanded = $allUSP.is(':visible');
            resetMembershipDurations($currentMembershipDurations, $membershipDuration);
            addMembershipPlanToCart(url, pid, $membershipTable, isUSPSectionExpanded, false);
        });
    });
};

/**
 * Handles redirect from the club details page to the checkout step 2
 */
const handleRedirectFromCDP = () => {
    const $membershipTables = $('.js-membership-block');
    const membershipTablesArray = $membershipTables.get();
    const membershipTable = membershipTablesArray && membershipTablesArray.find(table => $(table).hasClass('selected'));

    if (membershipTable) {
        const $membershipTable = $(membershipTable);
        const membershipUrl = $membershipTable.attr('data-url');
        const membershipName = $membershipTable.attr('data-name');
        const $membershipStepWrapper = $membershipTable.closest('.js-step-02-content');
        const $membershipDurationWrapper = $membershipStepWrapper.find('.js-membership-duration-wrapper');
        const $selectedMembershipDuration = $membershipDurationWrapper.find(`.js-membership-contract-length[data-membership-name=${membershipName}][type=radio].checked`);
        const durationId = $selectedMembershipDuration.attr('data-pid');

        addMembershipPlanToCart(membershipUrl, durationId, null, false, false);
    }
};

const getPreselectedMembershipInfo = () => {
    const $selectedMembershipTable = $('.js-membership-block.selected.highlighted');
    const membershipName = $selectedMembershipTable.attr('data-name');

    if (!membershipName) {
        return null;
    }

    const $selectedMembershipDuration = $(`.js-membership-contract-length[data-membership-name=${membershipName}][type=radio].checked`);
    const name = membershipName.charAt(0).toUpperCase() + membershipName.slice(1).toLowerCase();
    const duration = $selectedMembershipDuration.attr('data-duration');

    return {
        name,
        duration,
    };
};

/**
 * Handles counter in the checkout banner on step 2
 */
const countdown = () => {
    const $counter = $('#top-counter');

    if ($counter.length > 0) {
        const $counterWrapper = $('#top-counter-wrapper');
        const $daysValue = $('.js-top-counter-days');
        const $hoursValue = $('.js-top-counter-hours');
        const $minutesValue = $('.js-top-counter-minutes');
        const date = $counter.attr('data-date');
        const countDownDate = new Date(date).getTime();

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

            $daysValue.text(days);
            $hoursValue.text(hours);
            $minutesValue.text(minutes);

            if (distance < 0) {
                clearInterval(interval);
                $counterWrapper.addClass('d-none');
            }
        }, 1000);
    }
};

/**
 * equalizes inline height of all elements with the same selector
* @param {string} selector - can be any ccs class or id
 */
const equalizeElements = (selector) => {
    const elements = $(selector);
    if (elements.length === 0) return;

    // Remove inline height
    elements.css('min-height', '');

    // Measure heights and find the maximum
    let minHeight = 0;
    elements.each((index, element) => {
        const height = $(element).outerHeight();
        if (height > minHeight) {
            minHeight = height;
        }
    });

    // Apply the maximum height to all elements
    elements.css('min-height', `${minHeight}px`);
};

export {
    handleMembershipUSP,
    updateMembershipBlock,
    handleMembershipSelection,
    handleMembershipDuration,
    handleRedirectFromCDP,
    getPreselectedMembershipInfo,
    countdown,
    equalizeElements,
};
