import payementInitialize from './paymentScheduleUpdate';

/**
 * Toggles PayPal, ApplePay, Bizum, and iDeal buttons from the Adyen drop-in based on the validity of the Step 5 checkout input fields
 * @param {?HTMLElement} $paypalButton - PayPal button element
 * @param {?HTMLElement} $applepayButton - ApplePay button element
 * @param {?HTMLElement} $bizumButton - Bizum button element
 * @param {?HTMLElement} $idealButton - iDeal button element
 */
const toggleDropinButtons = ($paypalButton, $applepayButton, $bizumButton, $idealButton) => {
    const $accountHolder = $('#dwfrm_paymentDetails_accountHolder');
    const $accountNumber = $('#dwfrm_paymentDetails_accountNumber');
    const $sepaAgreement = $('#dwfrm_paymentDetails_agreeAutomaticSEPA');
    const $termsAndConditions = $('#termsAndConditionsCheck');

    const isAccountHolderValid = !$accountHolder.length || $accountHolder.valid();
    const isAccountNumberValid = !$accountNumber.length || $accountNumber.valid();
    const isSepaAgreementValid = !$sepaAgreement.length || $sepaAgreement.valid();
    const isTermsAndConditionsValid = !$termsAndConditions.length || $termsAndConditions.valid();

    if (isAccountHolderValid && isAccountNumberValid && isSepaAgreementValid && isTermsAndConditionsValid) {
        if ($paypalButton && $paypalButton.length > 0) {
            $paypalButton.removeClass('paypal-button-disabled');
        }
        if ($applepayButton && $applepayButton.length > 0) {
            $applepayButton.removeClass('applepay-button-disabled');
        }
        if ($bizumButton && $bizumButton.length > 0) {
            $bizumButton.removeClass('bizum-button-disabled');
        }
        if ($idealButton && $idealButton.length > 0) {
            $idealButton.removeClass('ideal-button-disabled');
        }
    } else {
        if ($paypalButton && $paypalButton.length > 0) {
            $paypalButton.addClass('paypal-button-disabled');
        }
        if ($applepayButton && $applepayButton.length > 0) {
            $applepayButton.addClass('applepay-button-disabled');
        }
        if ($bizumButton && $bizumButton.length > 0) {
            $bizumButton.addClass('bizum-button-disabled');
        }
        if ($idealButton && $idealButton.length > 0) {
            $idealButton.addClass('ideal-button-disabled');
        }
    }
};

/**
 * Updates membership duration plan HTML wrapper with the given HTML string
 * @param {string} membershipDurationPlanHTML - HTML string
 */
const updateMembershipDuration = membershipDurationPlanHTML => {
    if (!membershipDurationPlanHTML || typeof membershipDurationPlanHTML !== 'string') {
        return;
    }

    const memberShipDurationPlan = $('.js-membershipDurationPlan');
    const activeMemberShipDuration = memberShipDurationPlan.find('.js-first-part-checkboxes');
    activeMemberShipDuration.replaceWith(membershipDurationPlanHTML);
    payementInitialize();
};

/**
 * Updates payment schedule options on checkout step 5
 * @param {Object} data - Payload data
 */
const updatePaymentScheduleOptions = ({
    isFlex,
    isPromotionAvailable,
    is13PeriodDiscount,
    isLowEntryBarrierPromotion,
    isFoundingMember,
    subscriptionPrice,
    discountedMonthlyPrice,
    originalTotalPrice,
    totalPrice,
    promotionFlexLabel,
    promotionLabel4Weeks,
    promotionLabel52Weeks,
    promotion13Label4Weeks,
    promotion13Label52Weeks,
    defaultDescription4Weeks,
    lowEntryBarrierDescription4Weeks,
}) => {
    const $4WeeksPaymentScheduleOption = $('.js-payment-schedule.fourWeeks');
    const $52WeeksPaymentScheduleOption = $('.js-payment-schedule.52weeks');
    const $4WeeksDescription = $4WeeksPaymentScheduleOption.find('.js-promotion-description');

    $4WeeksDescription.html(isLowEntryBarrierPromotion ? lowEntryBarrierDescription4Weeks : defaultDescription4Weeks);

    if (isPromotionAvailable || isFoundingMember) {
        const $4WeeksDiscountedPrice = $4WeeksPaymentScheduleOption.find('.discounted-price');
        const $52WeeksDiscountedPrice = $52WeeksPaymentScheduleOption.find('.discounted-price');
        const $4WeeksStrikeThroughPrice = $4WeeksPaymentScheduleOption.find('.strike-through-price');
        const $52WeeksStrikeThroughPrice = $52WeeksPaymentScheduleOption.find('.strike-through-price');
        const $4WeeksPromotionLabel = $4WeeksPaymentScheduleOption.find('.sub-text');
        const $52WeeksPromotionLabel = $52WeeksPaymentScheduleOption.find('.sub-text');
        let label4Weeks;

        if (isFlex) {
            label4Weeks = promotionFlexLabel;
        } else if (is13PeriodDiscount) {
            label4Weeks = promotion13Label4Weeks;
        } else {
            label4Weeks = promotionLabel4Weeks;
        }

        if (discountedMonthlyPrice) {
            $4WeeksDiscountedPrice.html(discountedMonthlyPrice);
        }
        if (totalPrice) {
            $52WeeksDiscountedPrice.html(totalPrice);
        }
        if (subscriptionPrice) {
            $4WeeksStrikeThroughPrice.html(subscriptionPrice);
        }
        if (originalTotalPrice) {
            $52WeeksStrikeThroughPrice.html(originalTotalPrice);
        }
        if (label4Weeks) {
            $4WeeksPromotionLabel.html(label4Weeks);
        }
        if (promotion13Label52Weeks || promotionLabel52Weeks) {
            $52WeeksPromotionLabel.html(is13PeriodDiscount ? promotion13Label52Weeks : promotionLabel52Weeks);
        }
    } else {
        const $4WeeksPrice = $4WeeksPaymentScheduleOption.find('.card__price');
        const $52WeeksPrice = $52WeeksPaymentScheduleOption.find('.card__price');

        if (subscriptionPrice) {
            $4WeeksPrice.html(subscriptionPrice);
        }
        if (originalTotalPrice) {
            $52WeeksPrice.html(originalTotalPrice);
        }
    }
};

/**
 * Toggles one cent promotion content on checkout step 5
 * @param {string} paymentInterval - Payment interval (fourWeeks, 52weeks)
 * @param {boolean} isOneCentPromotion - True if one cent promotion is available, False otherwise
 */
const toggleOneCentPromotionContent = (paymentInterval, isOneCentPromotion) => {
    const is4Weeks = paymentInterval === 'fourWeeks';
    const $4Weeks = $('.js-per-4-weeks-asset');
    const $52Weeks = $('.js-per-52-weeks-asset');
    const $oneCentPromotionAsset = $('.js-one-cent-promotion-asset');
    const $oneCentPromotionDisclaimer = $('.js-one-cent-promotion-disclaimer');
    const $oneCentPromotionMobileDisclaimer = $('.js-mobile-summary-block');

    if (isOneCentPromotion) {
        $4Weeks.hide();
        $52Weeks.hide();
        $oneCentPromotionAsset.show();
    } else if (is4Weeks) {
        $4Weeks.show();
        $52Weeks.hide();
        $oneCentPromotionAsset.hide();
    } else {
        $4Weeks.hide();
        $52Weeks.show();
        $oneCentPromotionAsset.hide();
    }

    if (isOneCentPromotion && is4Weeks) {
        $oneCentPromotionDisclaimer.removeClass('display-none');
        $oneCentPromotionMobileDisclaimer.show();
    } else {
        $oneCentPromotionDisclaimer.addClass('display-none');
        $oneCentPromotionMobileDisclaimer.hide();
    }
};

export {
    toggleDropinButtons,
    updateMembershipDuration,
    updatePaymentScheduleOptions,
    toggleOneCentPromotionContent,
};
