/* eslint-disable */
var checkoutFlow = require('../gtm/checkoutFlow');
var formError = require('./formErrors');
var dropinConfig = null;
var dropin = null;
var adyenObj = null;
var brand = null;

function createOrder(component, orderId, actions, data, peopleId) {
    var url = $('#dropin-container').attr('data-placeorder');

    if (!url || !orderId) {
        window.location.href = $('#dropin-container').data('error');
        return;
    }

    var params = {
        orderid: orderId,
        peopleid: peopleId,
        brand: brand,
        adyenPaymentMethod: component.activePaymentMethod.displayName,
        dropinSessionAmount: component.props.amount.value
    };

    url += (url.indexOf('?') !== -1 ? '&' : '?') +
                    Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');
    // create an order on BE
    $.ajax({
        url: url,
        type: "POST",
        success: (response) => {
            if (response.error) {
                // Reinitialize dropin
                var priceIssue = response && response.priceIssue !== undefined ? response.priceIssue : false; //true if Adyen dropin session price is different to basket price
                dropin.closeActivePaymentMethod();
                $.ajax({
                    url: $('#dropin-container').attr('data-updatesession'),
                    contentType: 'application/json',
                    dataType: 'json',
                    type: 'POST',
                    data: JSON.stringify({priceIssue: priceIssue})
                }).done(function(responseUpdateSession) {
                    // updating people ID and order ID in the frontend
                    $('input[name=orderId]').val(responseUpdateSession.adyenSessionData.orderId);
                    $('input[name=peopleId]').val(responseUpdateSession.adyenSessionData.peopleId);
                    // Reinitialize dropin
                    dropin.unmount();
                    mountAdyenObj(reCrateAdyenConfig(responseUpdateSession.adyenSessionData));
                });
                renderFailedPayments('reload');
                //we need to reload the page to make sure we see correct method selected in storefront
                location.reload();
                throw Error(response.errorMessage);

            }
            if (response.continueUrl && response.orderID && response.orderToken) {
                let continueUrl = response.continueUrl;
                const urlParams = {
                    ID: response.orderID,
                    token: response.orderToken
                };

                continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                    Object.keys(urlParams).map((key) => `${key}=${encodeURIComponent(urlParams[key])}`).join('&');
                // TO DO: handle with onSelect(component)
                checkoutFlow.pushPaymentMethodSelected(component.activePaymentMethod.displayName);
                $('#dropin-container').data('success', continueUrl);
                if (actions && data) {
                    actions.resolve(data);
                }
                sessionStorage.clear();
            }
        },
        error: (err) => {
            $('.js-payment-method').prop('disabled', false);
            console.log('Error: ', err);
            window.location.href = $('#dropin-container').data('error');

        },
    });
}

function check3DS2(state, component) {
    // verify payment details for 3ds2 case
    var payload = state.data;
    dropin.setStatus('loading');
    $.ajax({
        url: $('#dropin-container').attr('data-3ds2'),
        contentType: 'application/json',
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(payload)
    }).done(function(response3ds2) {
        if (response3ds2.error) {
            // Reinitialize dropin
            dropin.closeActivePaymentMethod();

            $.ajax({
                url: $('#dropin-container').attr('data-updatesession'),
                contentType: 'application/json',
                dataType: 'json',
                type: 'POST',
                data: JSON.stringify({ orderId: $('input[name=orderId]').val() })
            }).done(function(responseUpdateSession) {
                var adyenSessionData = responseUpdateSession.adyenSessionData;
                var orderId = adyenSessionData && adyenSessionData.orderId;
                var peopleId = adyenSessionData && adyenSessionData.peopleId;

                // Update people ID and order ID in the frontend
                $('input[name=orderId]').val(orderId);
                $('input[name=peopleId]').val(peopleId);

                // Reinitialize dropin
                dropin.unmount();
                mountAdyenObj(reCrateAdyenConfig(adyenSessionData));
            });

            $('.js-payment-method').prop('disabled', false);
            renderFailedPayments(response3ds2.resultCode.toLowerCase());
            throw Error(response3ds2.errorMessage);
        }

        if (response3ds2.authorised) {
            dropin.setStatus('success');
            window.location.href = $('#dropin-container').data('success');

        } else if (response3ds2.action) {
            dropin.handleAction(response3ds2.action);
        } else {
            window.location.href = $('#dropin-container').data('error');
        }
    }).fail(function (xhr, error) {
        $('#creditErrorAlert').addClass('show');
        $('#creditErrorAlert').text(error);
        // Reinitialize dropin
        dropin.closeActivePaymentMethod();


        $.ajax({
            url: $('#dropin-container').attr('data-updatesession'),
            contentType: 'application/json',
            dataType: 'json',
            type: 'POST',
            data: JSON.stringify({orderId:$('input[name=orderId]').val()})
        }).done(function(responseUpdateSession) {
            // updating people ID and order ID in the frontend
            $('input[name=orderId]').val(responseUpdateSession.adyenSessionData.orderId);
            $('input[name=peopleId]').val(responseUpdateSession.adyenSessionData.peopleId);
            // Reinitialize dropin
            dropin.unmount();
            mountAdyenObj(reCrateAdyenConfig(responseUpdateSession.adyenSessionData));
        });


        renderFailedPayments('refused');
        throw Error(error)
    });
}

function renderFailedPayments (resultCode) {
    $('#creditErrorAlert').removeClass('display-none');
    $('#creditErrorAlert').addClass('show');
    $('#creditErrorAlert').text(window.resources['checkout.error.' + resultCode]);
}

function reCrateAdyenConfig (failResponse) {
    var data = {
        config: {
            locale: failResponse.locale,
            clientKey: failResponse.clientKey,
            environment: failResponse.environment,
            session: {
                id: failResponse.id,
                sessionData: failResponse.sessionData
            }
        }
    }

    return data;
}

async function mountAdyenObj(data) {
    var orderId = $('input[name=orderId]').val();
    var peopleId = $('input[name=peopleId]').val();

    if (dropin) {
        dropin.unmount();
    }
    if (!data.error) {
        dropinConfig = data.config;
    }
    if (!dropinConfig) {
        console.error('wrong adyen dropin config');
        return;
    }

    dropinConfig.beforeSubmit = (data, component, actions) => {
        orderId = $('input[name=orderId]').val();
        $('#creditErrorAlert').text('');
        $('#creditErrorAlert').removeClass('show');
        $('#errorAlert').removeClass('show');

        if (data.paymentMethod.type === 'paypal'
            || data.paymentMethod.type === 'applepay'
            || data.paymentMethod.type === 'bizum'
            || data.paymentMethod.type === 'ideal'
        ) {
            const defer = $.Deferred();
            const $accountHolder = $('#dwfrm_paymentDetails_accountHolder');
            const $accountNumber = $('#dwfrm_paymentDetails_accountNumber');
            const $sepaAgreement = $('#dwfrm_paymentDetails_agreeAutomaticSEPA');
            const $termsAndConditions = $('#termsAndConditionsCheck');

            const requiredName = !$accountHolder.length || $accountHolder.valid();
            const requiredIban = !$accountNumber.length || $accountNumber.valid();
            const requiredSepa = !$sepaAgreement.length || $sepaAgreement.valid();
            const requiredTermsCon = !$termsAndConditions.length || $termsAndConditions.valid();

            if (!requiredName || !requiredIban || !requiredSepa || !requiredTermsCon) {
                defer.reject();
            } else {
                const $form = $('.js-payment-method-form');
                $.ajax({
                    url: $form.data('action'),
                    method: 'POST',
                    data: $form.serialize(),
                    success(response) {
                        if (response.error) {
                            if (response.cartError) {
                                window.location.href = response.redirectUrl;
                                defer.reject();
                            } else if (response.fieldErrors && response.fieldErrors.length > 0) {
                                response.fieldErrors.forEach((error) => {
                                    if (Object.keys(error).length) {
                                        formError.loadFormErrors($form, error);
                                    }
                                });
                            } else {
                                // go to appropriate stage and display error message
                                defer.reject(response);
                            }
                        } else {
                            createOrder(component, orderId, actions, data, peopleId);
                        }
                    },
                    error() {
                        return;
                    },
                });
            }
        } else {
            createOrder(component, orderId, actions, data, peopleId);
        }
    };

    dropinConfig.onChange = (state, component) => {
        try {
            if (!state.isValid) {
                $('.js-payment-method').prop('disabled', false);
            }
        } catch (error) {
            console.error(error)
            $('.js-payment-method').prop('disabled', false);
        }
    };

    dropinConfig.onAdditionalDetails = (state, component) => {
        check3DS2(state, component);
    };

    dropinConfig.onActionHandled = () => {
        $('.js-payment-method').prop('disabled', true);
    };

    dropinConfig.onPaymentCompleted = (result, component) => {
        // To DO: handle redirect to checkout
        if (result.resultCode === 'Refused' || result.resultCode === 'Error') {
            try {
                //in case of resources missing or result code is a new code that we never handle before, we are doing this call in try and catch
                renderFailedPayments(result.resultCode.toString().toLowerCase());
                 //Update Order number in the session
                $.ajax({
                    url: $('#dropin-container').attr('data-updatesession'),
                    contentType: 'application/json',
                    dataType: 'json',
                    type: 'POST',
                    data: JSON.stringify({orderId:orderId})
                }).done(function(responseUpdateSession) {
                    // updating people ID and order ID in the frontend
                    $('input[name=orderId]').val(responseUpdateSession.adyenSessionData.orderId);
                    $('input[name=peopleId]').val(responseUpdateSession.adyenSessionData.peopleId);
                    orderId = $('input[name=orderId]').val();
                    peopleId = $('input[name=peopleId]').val();
                    // Reinitialize dropin
                    dropin.unmount();
                    mountAdyenObj(reCrateAdyenConfig(responseUpdateSession.adyenSessionData));
                });
            } catch (error) {
                console.error(error);
            }

            $('.js-payment-method').prop('disabled', false);

        } else {
            //Delete session variables when payment succesfull
            $.ajax({
                url: $('#dropin-container').attr('data-resetsession'),
                contentType: 'application/json',
                dataType: 'json',
                type: 'POST',
                data: JSON.stringify({orderId:orderId})
            }).done(function(res) {
                console.log('session variables deleted');
            }).then(function (stts,error) {
                dropin.setStatus('success');
                window.location.href = $('#dropin-container').data('success');
                $('.js-payment-method').prop('disabled', false);
            }).fail(function (stts,error) {
                dropin.setStatus('success');
                window.location.href = $('#dropin-container').data('success');
                $('.js-payment-method').prop('disabled', false);
            });
        }
    };

    dropinConfig.paymentMethodsConfiguration = {
        card: {
            onBrand: function (state, dropin) {
                brand = state.brand;
            },
            onError: function (state) {
                console.error('onError: ', state);

                try {
                    // This is an internal error on adyen side we are showing general error
                    renderFailedPayments('error');
                    adyenDropin();
                } catch (error) {
                    console.error(error);
                }

                $('.js-payment-method').prop('disabled', false);
            },
            hasHolderName: true,
            holderNameRequired: true,
            name: window.resources['adyen.credit.card.label']
        },
        paypal: {
            onError: function (state, dropin) {
                try {
                    console.log('error name for paypal: ' + state.name);
                    //in case of resources missing or result code is a new code that we never handle before, we are doing this call in try and catch
                    renderFailedPayments(state.name.toString().toLowerCase());
                     //Update Order number in the session
                    $.ajax({
                        url: $('#dropin-container').attr('data-updatesession'),
                        contentType: 'application/json',
                        dataType: 'json',
                        type: 'POST',
                        data: JSON.stringify({orderId:orderId})
                    }).done(function(responseUpdateSession) {
                        // updating people ID and order ID in the frontend
                        $('input[name=orderId]').val(responseUpdateSession.adyenSessionData.orderId);
                        $('input[name=peopleId]').val(responseUpdateSession.adyenSessionData.peopleId);
                        orderId = $('input[name=orderId]').val();
                        peopleId = $('input[name=peopleId]').val();
                        // Reinitialize dropin
                        dropin.unmount();
                        mountAdyenObj(reCrateAdyenConfig(responseUpdateSession.adyenSessionData));
                    });
                } catch (error) {
                    console.error(error);
                }
            },
        },
        applepay: {
            buttonType: 'plain',
            buttonColor: 'white-with-line',
        }
    };

    try {
        adyenObj = await AdyenCheckout(dropinConfig);
        dropin = adyenObj.create('dropin').mount('#dropin-container');
    } catch (error) {
        console.error('error during Adyen dropin initialization', error);
        throw Error(error);
    }
}

function adyenDropin() {
    $.ajax({
        url: $('#dropin-container').attr('data-url')
    }).done(function(response) {
        mountAdyenObj(response);
    }).fail(function (xhr, error) {
        console.error('failed to initialize Adyen dropin')
        throw Error(error);
    });
}

module.exports = adyenDropin;
